<template>
  <div>
    <table class="table table-hover table-sm table-bordered" v-if="getCountry(order.currencyDetails.country_id)">
      <thead>
      <tr>
        <th colspan="2">Price Information</th>
      </tr>
      </thead>
      <tbody>
      <tr v-if="order">
        <td>Country</td>
        <td>
       <span>
         <span
           :class="`currency-flag currency-flag-${getCountry(order.currencyDetails.country_id).currencyCode.toLowerCase()}`"></span>
            {{
           getCountry(order.currencyDetails.country_id).name
         }} ({{ $store.getters["geo/country"](order.currencyDetails.country_id).alpha3Code }})
       </span>
        </td>
      </tr>
      <tr>
        <td>Market factor</td>
        <td>{{ order.currencyDetails.market_factor }}</td>
      </tr>
      <tr>
        <td>Original price
        </td>
        <td>
          <credits-view v-if="!isNaN(order.currencyDetails.original_price)"
                        :amount="order.currencyDetails.original_price"
                        :currency-details="tvsCurrency"/>
          <span v-else>On request</span>
        </td>
      </tr>
      <tr>
        <td>After market factor
        </td>
        <td>
          <credits-view
            v-if="!isNaN(order.currencyDetails.subtotal) && order.currencyDetails.original_price !== 'On request'"
            :amount="order.currencyDetails.original_price * order.currencyDetails.market_factor"
            :currency-details="tvsCurrency"/>
          <span v-else>On request</span>
        </td>
      </tr>
      <tr v-if="order.description.description.coupon">
        <td>Coupon discount:</td>
        <td>
          <credits-view :amount="order.description.description.coupon.discount" :currency-details="tvsCurrency"/>
        </td>
      </tr>
      <tr v-if="order.isPersonalFlasher && userGroupPrice">
        <td>
          Personal flasher Extra fee : {{ userGroupPrice.overrunPf * 100 }}%
        </td>
        <td class="border-0">
          <credits-view :amount="(order.price * userGroupPrice.overrunPf)" :currency-details="tvsCurrency"/>
        </td>
      </tr>
      <tr>
        <td>Final price
        </td>
        <td>
          <span v-if="isNaN(priceFinal)">{{ priceFinal }}</span>
          <credits-view v-else :amount="priceFinal" :currency-details="tvsCurrency"/>
        </td>
      </tr>
      <tr>
        <td>
          Price factor / currency exchange
        </td>
        <td>
          1 Credit = {{ currencyToUser(1, order.currencyDetails) }} {{ order.currencyDetails.currency_code }}
        </td>
      </tr>
      <tr>
        <td>Price in {{ order.currencyDetails.code }}</td>
        <td>{{ currencyToUser(order.price, order.currencyDetails) }}</td>
      </tr>
      </tbody>
    </table>
    <table class="table table-hover table-sm table-bordered" v-if="profitDistributor">
      <tr>
        <th colspan="2">Distributor profit</th>
      </tr>
      <tr>
        <td>To</td>
        <td>{{ profitDistributor.distributor.fullName }}</td>
      </tr>
      <tr>
        <td>TVS Price</td>
        <td>
          <credits-view :amount="profitDistributor.original_price" :currency-details="tvsCurrency"/>
        </td>
      </tr>
      <tr>
        <td>Modified Price</td>
        <td>
          <credits-view :amount="profitDistributor.modified_price" :currency-details="tvsCurrency"/>
        </td>
      </tr>
      <tr>
        <td>Profit</td>
        <td>
          <credits-view :amount="profitDistributor.order_profit" :currency-details="tvsCurrency"/>
        </td>
      </tr>
      <template v-if="profitDistributor.upgrade_profit">
        <tr>
          <td>Upgrade profit</td>
          <td>
            <credits-view :amount="profitDistributor.upgrade_profit" :currency-details="tvsCurrency"/>
          </td>
        </tr>
        <tr>
          <td>Total profit</td>
          <td>
            <credits-view :amount="profitDistributor.upgrade_profit + profitDistributor.order_profit"
                          :currency-details="tvsCurrency"/>
          </td>
        </tr>
      </template>
    </table>
  </div>

</template>

<script>
import {mapGetters, mapState} from 'vuex';
import CreditsView from '@/components/common/CreditsView';

export default {
  name: 'Price',
  components: {CreditsView},
  props: ['orderInfo', 'userGroupPrice', 'profitDistributor'],
  computed: {
    ...mapGetters('geo', {
      getCountry: 'country',
      priceFor: 'priceFor',
      currencyToUser: 'currencyToUser'
    }),
    ...mapState('geo', ['tvsCurrency']),
    order() {
      return this.orderInfo;
    },
    priceFinal() {
      if (this.order.price === 9999) {
        return 'On Request';
      }
      if (this.userGroupPrice && this.order.isPersonalFlasher) {
        return this.order.price + (this.userGroupPrice.overrunPf * this.order.price);
      } else {
        return this.order.price;
      }
    }
  },
  watch: {
    order: {
      immediate: true,
      handler(loc) {
      }
    }
  },
};

</script>

<style scoped>

</style>
