<template>
  <div class="invoice container-fluid"
       :class="{'for-no-print': !fixedbottom, 'watermark': order.status === 'Cancelled', 'print': isToPdf} ">
    <div class="row">
      <div class="col col-7 text-center bg-black" style="position: relative">
        <div class="logo">
          <img alt="TVS "
               :src="require('@/assets/images/565953f1-8de2-4195-82b3-c8cf203e76c9.png')"
               title="TVS" width="337">
          <p class="name-company">{{ companyOwner.subtitle }}</p>
        </div>
      </div>
      <div class="col col-5 pt-5 pb-3" style="min-height: 14vw">
        <div class="row">
          <div class="col-12">
            <h5 class="pb-5"><strong>{{ companyOwner.title }}</strong></h5>
          </div>
          <div class="col-12 text-black" v-if="!dummy">
            <div class="row">
              <div class="col col-4"><h6 v-show="showAddressSection"><strong>Address</strong></h6></div>
              <div class="col"><h6></h6></div>
            </div>
            <div class="row">
              <div class="col col-4"><h6 v-show="showAddressSection"><strong>Street:</strong></h6></div>
              <div class="col"><h6 v-show="showAddressSection">{{ companyOwner.street }}</h6></div>
            </div>


            <div class="row">
              <div class="col col-4">
                <h6 v-show="showAddressSection"><strong>City:</strong></h6>
              </div>
              <div class="col">
                <h6 v-show="showAddressSection">{{ companyOwner.city }}</h6>
              </div>
            </div>

            <div class="row">
              <div class="col col-4">
                <h6 v-show="showAddressSection"><strong>NIT (VAT nr.):</strong></h6>
              </div>
              <div class="col">
                <h6 v-show="showAddressSection">{{ companyOwner.taxnr }}</h6>
              </div>
            </div>

            <div class="row">
              <div class="col col-4"><h6><strong>Email:</strong></h6></div>
              <div class="col"><h6>{{ companyOwner.email }}</h6></div>
            </div>
            <div class="row">
              <div class="col col-4"><h6 v-show="showAddressSection"><strong>Quotation nr:</strong></h6></div>
              <div v-if="order" class="col"><h6 v-show="showAddressSection">
                <span v-if="order.ownerId !== null"></span><span>{{ order.invoiceNumber }}</span></h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row bg-red py-3">
      <div class="col col-7">
        <div class="row no-gutters">
          <div class="col col-9 offset-2 invoice-title">
            <h2 class="text-white">{{ invoiceTitles.title1 }}</h2>
          </div>
        </div>
      </div>
      <div class="col col-5">
        <div class="row">
          <div class="col col-5"><h6 class="text-white">{{ invoiceTitles.title2 }} #:</h6></div>
          <div v-if="order" class="col"><h6 class="text-white">
            <span v-if="order.ownerId !== null">OCR-</span>{{ order.invoiceNumber }}
          </h6></div>
        </div>
        <div class="row">
          <div class="col col-5"><h6 class="text-white">Date:</h6></div>
          <div v-if="order" class="col"><h6 class="text-white">{{ order.createdAt.slice(0, 10) }}</h6></div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-7 bg-black pt-5"></div>
    </div>
    <div class="row">
      <div class="col col-7">
        <div class="row">
          <div class="col col-2 bg-black py-4"></div>
          <div class="col col-10 py-4 bg-gray">
            <h3><strong>Item</strong> Description</h3>
          </div>
        </div>
      </div>
      <div class="col col-5 text-center">
        <div class="row no-gutters">
          <div class="col py-4"><h3>Price</h3></div>
          <div class="col py-4"><h3>Qty</h3></div>
          <div class="col py-4"><h3>Total</h3></div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col col-7">
        <div class="row">
          <div class="col col-2 bg-black py-4"></div>
          <div v-if="order" class="col col-10 py-4 bg-gray">
            <h5><strong>
              <credits-view :amount="order.credit" :currency-details="order.currencyDetails"/>
            </strong> software credits. incl. <strong
              class="">{{ order.discount }}%</strong> discount
            </h5>
          </div>
        </div>
      </div>
      <div v-if="order" class="col col-5 text-center" style="padding-right: 50px;">
        <div class="row no-gutters">
          <div class="col py-4">
            <h5>
              <currency-price-view :amount="order.price" :currency-details="currencyDetails" no-switch/>
            </h5>
          </div>
          <div class="col py-4 "><h5>1</h5></div>
          <div class="col py-4  text-right">
            <h5>
              <currency-price-view :amount="order.price" :currency-details="currencyDetails" no-switch/>
            </h5>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="order.extraFee>0">
      <div class="col col-7">
        <div class="row">
          <div class="col col-2 bg-black py-4"></div>
          <div v-if="order" class="col col-10 py-4 bg-gray">
            <h5>
              Payment cost
            </h5>
          </div>
        </div>
      </div>
      <div v-if="order" class="col col-5 text-center" style="padding-right: 50px;">
        <div class="row no-gutters">
          <div class="col py-4">
            <h5>
              <currency-price-view :amount=" order.extraFee" :currency-details="currencyDetails" no-switch/>
            </h5>
          </div>
          <div class="col py-4 "><h5></h5></div>
          <div class="col py-4  text-right">
            <h5>
              <currency-price-view :amount=" order.extraFee" :currency-details="currencyDetails" no-switch/>
            </h5>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col col-7" style="position: relative">
        <div class="row" style="height: 100%">
          <div class="col col-2 bg-black pt-5"></div>
          <div class="col col-10 bg-gray"></div>
        </div>
      </div>
      <div class="col col-5" style="padding-top: 180px; padding-bottom:20px; padding-right: 50px">
        <div class="row">
          <div class="col py-2 offset-3"><h5>Subtotal</h5></div>
          <div class="col py-2  text-right">
            <h5>
              <currency-price-view :amount="(order.price + Number(order.extraFee))" :currency-details="currencyDetails"
                                   no-switch/>
            </h5>
          </div>
        </div>
        <div class="row">
          <div class="col py-2 offset-3"><h5>0% VAT</h5></div>
          <div class="col py-2  text-right"><h5>{{ 0 }}</h5></div>
        </div>
        <div v-if="order" class="row">
          <div class="col py-2 offset-3"><h5>Total</h5></div>
          <div class="col py-2  text-right">
            <h5>
              <currency-price-view :amount="(order.price + Number(order.extraFee))" :currency-details="currencyDetails"
                                   no-switch/>
            </h5>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="mx-auto col-10 row no-gutters">
        <div class="col col-6 pr-3">
          <div class="border p-3">
            <h5 class="pb-4"><strong>{{ invoiceTitles.title3 }}</strong></h5>
            <div class="row" v-if="company && customInvoiceConfiguration.companyName.status">
              <div class="col col-5"><h6>Company name:</h6></div>
              <div class="col"><h6>{{ company.nameCompany }}</h6></div>
            </div>
            <div class="row" v-if="company && customInvoiceConfiguration.attentionTo.status">
              <div class="col col-5"><h6>Attention to:</h6></div>
              <div class="col"><h6>{{ company.legalRepresentative }}</h6></div>
            </div>
            <div class="row" v-if="company && customInvoiceConfiguration.address1.status">
              <div class="col col-5"><h6>Address 1:</h6></div>
              <div class="col"><h6>{{ company.address }}</h6></div>
            </div>
            <div class="row" v-if="company && customInvoiceConfiguration.address2.status">
              <div class="col col-5"><h6>Address 2:</h6></div>
              <div class="col"><h6>{{ company.address2 }}</h6></div>
            </div>
            <div class="row" v-if="company && customInvoiceConfiguration.city.status">
              <div class="col col-5"><h6>City:</h6></div>
              <div class="col"><h6>{{ company.city }}</h6></div>
            </div>
            <div class="row" v-if="company  && customInvoiceConfiguration.zipCode.status">
              <div class="col col-5"><h6>Zip code:</h6></div>
              <div class="col"><h6>{{ company.zipCode }}</h6></div>
            </div>
            <div class="row" v-if="company && customInvoiceConfiguration.country.status">
              <div class="col col-5"><h6>Country:</h6></div>
              <div class="col"><h6>{{ company.country }}</h6></div>
            </div>
            <div class="row" v-if="company && customInvoiceConfiguration.vatNumber.status">
              <div class="col col-5"><h6>NIT (VAT nr.): </h6></div>
              <div class="col"><h6>{{ company.vatNumber }}</h6></div>
            </div>
          </div>
        </div>
        <div class="col col-6 pl-3">
          <div class="border p-3" style="height: 100%" v-if="showAddressSection">
            <h5 class="pb-4"><strong>{{ invoiceTitles.title4 }}</strong></h5>
            Please use platform payment gateway
          </div>
        </div>
      </div>
    </div>
    <div class="text-center my-5">
      <h5 class="mb-2" v-if="showText"> {{ companyOwner.title.toUpperCase() }} IS THE OFFICIAL TVS AUTHORISED
        DISTRIBUTOR FOR THE <span v-if="companyOwner.country">{{ companyOwner.country.toUpperCase() }}</span></h5>
      <h5>Thank you for your business!</h5>
    </div>
    <div class="mt-5 row bg-gray text-center" :class="{'fixed-bottom': fixedbottom}">
      <div class="col p-5" v-html="companyOwner.resolution"></div>
    </div>

    <div class="text-center p-5" v-if="showDownload">
      <button :disabled="!showButton" class="btn btn-primary" target="_blank" @click="download()" href="#">
        <i class="fas fa-download"></i> Download
      </button>
    </div>
  </div>
</template>

<script>
import {post} from '@/services/api';
import {resendInvoice, resendInvoiceDummy} from '@/services/endpoint.json';
import axios from 'axios';
import CurrencyPriceView from '@/components/common/CurrencyPriceView';
import CreditsView from '@/components/common/CreditsView';
import Price from '@/views/dashboard/order-detail/Price';
import {INVOICE_INFORMATION_DEFUALT} from '@/constants';
import {mapGetters} from 'vuex';

export default {
  name: 'InvoiceView',
  components: {
    Price,
    CreditsView,
    CurrencyPriceView
  },
  props: {
    order: Object,
    dummy: {default: false},
    company: Object,
    fixedbottom: {default: true},
    showDownload: {default: false},
    showAddressSection: {default: true},
    companyOwner: {
      type: Object,
      default: INVOICE_INFORMATION_DEFUALT
    },
    isDistributor: {default: true},
    invoiceTitles: {
      type: Object,
      default: {
        title1: 'INVOICE',
        title2: 'Invoice',
        title3: 'Invoice to',
        title4: 'Payment info',
      }
    },
    customInvoiceConfiguration: {
      default: {
        companyName: {status: true, value: ''},
        vatNumber: {status: false, value: ''},
        attentionTo: {status: true, value: ''},
        address1: {status: true, value: ''},
        address2: {status: true, value: ''},
        city: {status: true, value: ''},
        zipCode: {status: true, value: ''},
        country: {status: true, value: ''}
      }
    },
    isToPdf: {
      default: false,
      type: Boolean
    }
  },
  data: () => ({
    showButton: true,
    showImage: false,
    showText: false,
  }),
  computed: {
    ...mapGetters('geo', ['currencyToUser', 'creditsToLocalCredits', 'currencyCreditSymbol']),
    currencyDetails() {
      let currency = this.order.currencyDetails;
      if (this.order.ownerId == null) {
        currency = this.$store.state.geo.tvsCurrency;
      }
      return currency;
    }
  },
  watch: {
    companyOwner: {
      immediate: true,
      handler(eo) {
        this.showImage = true;
      }
    },
    order: function () {
      if (!!this.order.id) {
        if (this.order.ownerId !== null) {
          this.showDownload = false;
        }
        this.showImage = true;
      }
    },
    isDistributor: {
      immediate: true,
      handler(e) {
      }
    },
  },
  methods: {
    async download() {
      this.showButton = false;
      const urlEndpoint = (this.dummy) ? resendInvoiceDummy : resendInvoice;
      const {data} = await post(urlEndpoint, {id: this.order.id}, true);
      axios({url: data.message, method: 'GET', responseType: 'blob',}).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        const title = this.order.ownerId === null ? 'TVS INVOICE' : 'ORDER NUMBER'
        link.setAttribute('download', `${title} ${this.order.invoiceNumber}.pdf`); //or any other extension
        document.body.appendChild(link);
        link.click();
        this.showButton = true;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.for-no-print {
  strong {
    font-weight: 400;
  }

  h5, h6 {
    font-weight: 300;
  }
}

.logo {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;

  img {
    width: 70%;
  }

  p {
    margin-top: 10px;
    text-align: center;
    font-size: 25px;
    font-weight: 300;
    letter-spacing: 4px;
    color: white;
  }
}

.invoice-title {

  h2 {
    font-size: 2rem;
    line-height: 1;
  }
}

h2, h3, h4, h5, h6 {
  font-weight: 300;
  color: #000;
  margin: 0;
  padding: 0;
}

h5, h6 {
  font-weight: 400;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.text-black {
  color: #0b0b0b !important;
}

.pb-extend {
  padding-bottom: 200px !important;
}

.bg-red {
  background-color: #fa385a;
}

.bg-black {
  background-color: #2c3e50;
}

.bg-gray {
  background-color: #f3f5f2;
}

.pt-1vw {
  padding-top: 1vw;
}

.pt-2vw {
  padding-top: 2vw;
}

strong {
  font-weight: 500;
}

.watermark {
  position: relative;
}

.watermark::after {
  content: "";
  z-index: 999;
  width: 100%;
  background: url("../../../assets/images/cancelled.png"); // ../assets/images/cancelled2.png
  background-repeat: no-repeat;
  opacity: 0.2;
  top: 25%;
  left: 20%;
  bottom: 10%;
  right: 20%;
  position: absolute;
}

.watermark.print::after {
  top: 32%;
  height: 80vh;
}
</style>
